export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '企业',
    prop: 'orgName'
  }, {
    label: '用户',
    prop: 'userId'
  }, {
    label: '工单日期',
    prop: 'orderDate'
  }, {
    label: '工单',
    prop: 'orderNo'
  }, {
    label: '联系人',
    prop: 'contactName'
  }, {
    label: '电话',
    prop: 'telephone'
  }, {
    label: '园区编号',
    prop: 'areaId'
  }, {
    label: '会议室编号',
    prop: 'roomId'
  }, {
    label: '预约日期',
    prop: 'roomDate'
  }, {
    label: '时间编号',
    prop: 'timeId'
  }, {
    label: '开始时间',
    prop: 'beginTime'
  }, {
    label: '结束时间',
    prop: 'endTime'
  }, {
    label: '联系人',
    prop: 'contactName'
  }, {
    label: '电话',
    prop: 'telephone'
  }, {
    label: '会议用途',
    prop: 'meetingUse'
  }, {
    label: '会议活动',
    prop: 'meetingContent'
  }, {
    label: '会议议题',
    prop: 'meetingTheme'
  }, {
    label: '议题库',
    prop: 'meetingSubjectPool'
  }, {
    label: '议题',
    prop: 'meetingSubject'
  }, {
    width: 150,
    label: '状态',
    prop: 'orderStatus',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待处理',
        value: 0
      }, {
        label: '已安排',
        value: 1
      }, {
        label: '处理中',
        value: 2
      }, {
        label: '处理完成',
        value: 3
      }
    ]
  }, {
    label: '备注',
    prop: 'remark'
  }]

}
